.blog h1{
    color: white;
}
.blog h2{
    color: white;
}
.blog h3{
    color: white;
}
.blog h4{
    color: white;
}
.blog h5{
    color: white;
}
.blog a{
    color: darkblue;
}
.blog p{
    color: white;
}
.prose :where(code):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
   color: white;
   background: #676767;
   padding: 0.1rem;
   border-radius: 5px;
}

.prose :where(strong):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
    color: white;
}

.blog-image {
    width: 100%;
    height: 400px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .blog-image img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    background-size: contain;
  }