.blog h1{
    color: white;
}
.blog h2{
    color: white;
}
.blog h3{
    color: white;
}
.blog h4{
    color: white;
}
.blog h5{
    color: white;
}
.blog a{
    color: darkblue;
}
.blog p{
    color: white;
}