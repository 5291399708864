@media (min-width: 576px) {
    .sahu {
        left:calc(50% - 36px);
        width: 400px;
        top: 150px
    }
}

@media (min-width: 992px) {
    .sahu {
        right:0;
        width: 400px;
        bottom: 0;
        top: auto;
        left: auto
    }
}

@media (min-width: 1200px) {
    .sahu {
        right:-155px;
        width: auto
    }
}

@media (min-width: 1800px) {
    .sahu {
        right:0
    }
}

.sahu .image {
    height: auto;
    position: relative;
    z-index: 8
}

@media (min-width: 992px) {
    .sahu .image {
        height:570px
    }
}

@media (min-width: 1200px) {
    .sahu .image {
        height:620px
    }
}

@media (min-width: 1800px) {
    .sahu .image {
        height:950px
    }
}

.sahu .shape-half {
    bottom: 0%;
    left: 50%;
    position: absolute;
    width: 100px;
    height: 205px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 95px;
    border: 1px solid rgba(255,255,255,.25);
    border-left: 0;
    z-index: -1
}

@media (min-width: 576px) {
    .sahu .shape-half {
        width:260px;
        height: 400px;
        border-top-right-radius: 200px;
        border-bottom-right-radius: 200px
    }
}

@media (min-width: 992px) {
    .sahu .shape-half {
        top:97px;
        left: 32%;
        width: 260px;
        height: 464px;
        border-top-right-radius: 250px;
        border-bottom-right-radius: 250px
    }
}

@media (min-width: 1200px) {
    .sahu .shape-half {
        width: 310px;
        height: 480px;
        border-top-right-radius: 350px;
        border-bottom-right-radius: 350px;
    }
}

@media (min-width: 1800px) {
    .sahu .shape-half {
        width:400px;
        height: 800px;
        border-top-right-radius: 400px;
        border-bottom-right-radius: 400px
    }
}

.sahu .shape-half::before {
        bottom: 0px;
        display: none;
    left: -25px;
    position: absolute;
    content: "";
    width: 100px;
    height: 193px;
    border-top-right-radius: 400px;
    border-bottom-right-radius: 400px;
    border: 1px solid rgba(255,255,255,.05);
    border-left: 0;
    z-index: -1;
    /* visibility: hidden; */
    /* opacity: 0;*/
}

@media (min-width: 992px) {
    .sahu .shape-half::before {
        visibility:visible;
        opacity: 1;
        bottom: 50px;
        display: block;
        left: 0;
        width: 220px;
        height: 370px;
        border-top-right-radius: 220px;
        border-bottom-right-radius: 220px
    }
}

@media (min-width: 1200px) {
    .sahu .shape-half::before {
        bottom:23px;
        left: 0;
        width: 270px;
        height: 430px;
        border-top-right-radius: 350px;
        border-bottom-right-radius: 350px
    }
}

@media (min-width: 1800px) {
    .sahu .shape-half::before {
        bottom:68px;
        left: -75px;
        width: 400px;
        height: 660px;
        border-top-right-radius: 400px;
        border-bottom-right-radius: 400px
    }
}

.sahu .shape-half::after {
        bottom: -20px;
    left: -8px;
    position: absolute;
    content: "";
    width: 130px;
    display: none;
    height: 244px;
    border-top-right-radius: 400px;
    border-bottom-right-radius: 400px;
    border: 1px solid rgba(255,255,255,.05);
    border-left: 0;
    z-index: -1;
    /* visibility: visible; */
    /* opacity: 0;*/
}

@media (min-width: 992px) {
    .sahu .shape-half::after {
        visibility:visible;
        opacity: 1;
        bottom: -28px;
        left: -69px;
        display: block;
        width: 396px;
        height: 540px;
        border-top-right-radius: 340px;
        border-bottom-right-radius: 340px
    }
}

@media (min-width: 1200px) {
    .sahu .shape-half::after {
        bottom:-38px;
        left: -64px;
        width: 415px;
        height: 550px;
        border-top-right-radius: 583px;
        border-bottom-right-radius: 630px
    }
}

@media (min-width: 1800px) {
    .sahu .shape-half::after {
        bottom:-46px;
        left: 24px;
        width: 450px;
        height: 900px;
        border-top-right-radius: 450px;
        border-bottom-right-radius: 450px
    }
}

.sahu .social-link a {
    display: block;
    text-align: center;
    color: #fff;
    width: 25px;
    height: 25px;
    line-height: 35px;
    font-size: 16px;
    border-radius: 50%;
    border: 1px solid #fff;
    z-index: 11;
    position: absolute;
    background: #252734;
    padding: 2.9px;
}

@media (min-width: 576px) {
    .sahu .social-link a {
        width:45px;
        height: 45px;
        line-height: 45px
    }
}

@media (min-width: 768px) {
    .sahu .social-link a {
        font-size:20px;
        width: 45px;
        height: 45px;
        line-height: 45px
    }
}

@media (min-width: 992px) {
    .sahu .social-link a {
        padding: 6px;
        font-size:35px;
        width: 60px;
        height: 60px;
        line-height: 60px
    }
}

@media (min-width: 1200px) {
    .sahu .social-link a {
        width:50px;
        height: 50px;
        line-height: 60px
    }
}

@media (min-width: 1800px) {
    .sahu .social-link a {
        width:80px;
        height: 80px;
        line-height: 80px
    }
}

.sahu .social-link a:hover {
    color: #252734;
    background: #fff
}

.sahu .social-link a:nth-child(1) {
    left: 150px;
    top: 45px
}

@media (min-width: 576px) {
    .sahu .social-link a:nth-child(1) {
        left:315px;
        top: 110px
    }
}

@media (min-width: 992px) {
    .sahu .social-link a:nth-child(1) {
        left:335px;
        top: 96px
    }
}

@media (min-width: 1200px) {
    .sahu .social-link a:nth-child(1) {
        left:355px;
        top: 96px
    }
}

@media (min-width: 1800px) {
    .sahu .social-link a:nth-child(1) {
        left:590px;
        top: 185px
    }
}

.sahu .social-link a:nth-child(2) {
    left: 180px;
    top: 90px
}

@media (min-width: 576px) {
    .sahu .social-link a:nth-child(2) {
        left:395px;
        top: 170px
    }
}

@media (min-width: 992px) {
    .sahu .social-link a:nth-child(2) {
        left:430px;
        top: 188px
    }
}

@media (min-width: 1200px) {
    .sahu .social-link a:nth-child(2) {
        left:450px;
        top: 188px
    }
}

@media (min-width: 1800px) {
    .sahu .social-link a:nth-child(2) {
        left:715px;
        top: 336px
    }
}

.sahu .social-link a:nth-child(3) {
    left: 185px;
    top: 150px
}

@media (min-width: 576px) {
    .sahu .social-link a:nth-child(3) {
        left:435px;
        top: 255px
    }
}

@media (min-width: 992px) {
    .sahu .social-link a:nth-child(3) {
        left:470px;
        top: 325px
    }
}

@media (min-width: 1200px) {
    .sahu .social-link a:nth-child(3) {
        left:485px;
        top: 325px
    }
}

@media (min-width: 1800px) {
    .sahu .social-link a:nth-child(3) {
        left:758px;
        top: 540px
    }
}

.sahu .social-link a:nth-child(4) {
    left: 155px;
    top: 200px;
}

@media (min-width: 576px) {
    .sahu .social-link a:nth-child(4) {
        left:430px;
        top: 348px
    }
}

@media (min-width: 992px) {
    .sahu .social-link a:nth-child(4) {
        left:400px;
        top: 480px
    }
}

@media (min-width: 1200px) {
    .sahu .social-link a:nth-child(4) {
        left:415px;
        top: 480px
    }
}

@media (min-width: 1800px) {
    .sahu .social-link a:nth-child(4) {
        left:687px;
        top: 740px
    }
}
.video-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 15px
}



@media (min-width:992px) {
    .video-link {
        margin-top: 40px
    }
    .video-link .video-text {
    padding-left: 30px
}
}

@media (min-width:1200px) {
    .video-link {
        margin-top: 50px
    }
}

@media (min-width:1400px) {
    .video-link {
        margin-top: 125px
    }
}

@media (min-width:1800px) {
    .video-link {
        margin-top: 125px
    }
}
@-webkit-keyframes animate-ripple {
    50% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0)
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0)
    }
}

@keyframes animate-ripple {
    50% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0)
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0)
    }
}

.wave-btn {
    display: block;
    position: relative;
    width: 60px;
    height: 60px;
    background: #2a2c39;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media (min-width:992px) {
    .wave-btn {
        width: 100px;
        height: 100px
    }
}

.ripple,
.ripple::after,
.ripple::before {
    position: absolute;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    z-index: -1;
    -webkit-animation: animate-ripple 3s infinite linear backwards;
    animation: animate-ripple 3s infinite linear backwards;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1
}

.ripple::before {
    position: absolute;
    content: "";
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.ripple::after {
    position: absolute;
    content: "";
    -webkit-animation-delay: .9s;
    animation-delay: .9s
}
